import React from 'react';
import { string, shape } from 'prop-types';
import classnames from 'classnames';
import { loadable } from 'nordic/lazy';
import { hyphenatedbem } from '../../lib/hyphenatedbem';

const CardTooltip = loadable(() => import('./index'));

const namespace = 'ui-vpp-card-tooltip';

const CardTooltipComponentWrapper = ({ cardTooltip, className }) => {
  if (!cardTooltip) {
    return null;
  }
  return (
    <CardTooltip
      {...cardTooltip}
      className={classnames(className, namespace, hyphenatedbem(cardTooltip.component_id || cardTooltip.id))}
    />
  );
};

CardTooltipComponentWrapper.propTypes = {
  cardTooltip: shape({}),
  className: string,
};

CardTooltipComponentWrapper.defaultProps = {
  className: null,
  cardTooltip: null,
};

export default CardTooltipComponentWrapper;
