const scrollToAndFocusQAI = (selector, focusDelay = 1000) => {
  const element = document.querySelector(selector);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', inline: 'center' });
    setTimeout(() => {
      const focusElement = document.querySelector(`${selector}-ai-form-input`);
      if (focusElement) {
        focusElement.focus();
      }
    }, focusDelay);
  }
};

export { scrollToAndFocusQAI };
