import React from 'react';
import { node, string, shape } from 'prop-types';
import getDisplayName from '../../lib/get-display-name';
import CardTooltipWrapper from './card-tooltip-wrapper';

const withCardTooltip = (WrappedComponent, className) => {
  const withCardTooltipComponent = ({ card_tooltip, ...props }) => (
    <>
      <WrappedComponent {...props} />
      <CardTooltipWrapper cardTooltip={card_tooltip} className={className} />
    </>
  );

  withCardTooltipComponent.propTypes = {
    card_tooltip: shape({}),
  };

  withCardTooltipComponent.defaultProps = {
    card_tooltip: null,
  };

  withCardTooltipComponent.displayName = `withCardTooltipComponent(${getDisplayName(WrappedComponent)})`;

  return withCardTooltipComponent;
};

withCardTooltip.propTypes = {
  WrappedComponent: node.isRequired,
  className: string,
};

withCardTooltip.defaultProps = {
  className: null,
};

export default withCardTooltip;
